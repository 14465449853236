export const CUSTOMER_LOGIN_API = "customer/customer-login";//used
export const GENERATE_OTP = "/save-otp";//used
export const MATCH_OTP = "/match-otp";//used
export const CUSTOMER_DETAIL = "customer/customer-detail";
export const UPDATE_CUSTOMER = "customer/update-customer";//used
export const GET_PRODUCT = "product/get";
export const CREATE_ORDER = "order/create";//used
export const UPLOAD_PRESCRIPTION = "/customer/prescription-upload";//used
export const UPLOAD_PRESCRIPTION_UPDATE_ORDER = "/customer/prescription-upload-update-order";//used
export const GET_PRESCRIPTION = "/customer/get-prescription";
export const ORDER_LIST_PATH = "/order/order-list";//used
export const UPDATE_ORDER_STATUS = "/order/update-satatus";
export const GET_CUSTOMER_OUT = "/sap/make-order-customer-xlsx";//used
export const SEND_WHATSAPP_MESSAGE = "/send-delivery-message";
export const GENERATE_SMS_SERVICE = "/send-place-order-msg";
export const MR_LOGIN_API = "/mr/login";
export const MR_DETAIL = "/mr/list";
export const MR_INFO = "/mr/info";
export const USER_DETAIL = "/mr/user-list";
export const USER_INFO = "/mr/user-info";
export const GET_DOCTOR_BY_MR = "/mr/get-doctor-by-mr";
export const CALL_CENTER_INFO = "/callcenter/info";//used
export const CALL_CENTER_DETAIL = "/callcenter/list";//used
export const STOCKIST_INFO = "/stockist/info";//used
export const STOCKIST_DETAIL = "/callcenter/list";//used
export const GET_CUSTOMER_DETAILS_OUT = "/sap/make-customer-xlsx";
// export const GET_ORDER_DETAILS_OUT = "/sap/make-order-xlsx";
export const CREATE_FEEDBACK = "/customer/create-feedback";
export const FEEDBACK_LIST = "/customer/feedback-list";
export const INITIATE_PAYMENT = "/ccavRequestHandler";
export const GET_ORDERS = "/customer/get-orders";//used
export const GET_ENC_REQUEST = "/get-enc-request";
export const USER_LOGIN_API = "/user-login-dgf";//used
export const CUSTOMER_LOGIN_TRACKING_API = "/customer-login-tracking";
export const GET_ORDER_DETAILS_BY_ID = "/get-order-details-by-id";//used
export const GET_ORDER_DETAILS_BY_ID_TRACK = "/get-order-details-by-id-track";//used
export const DOCTOR_LOGIN_API = "/doctor/login";
export const DOCTOR_DETAIL = "/doctor/details";
export const DOCTOR_INFO = "/doctor/info";
export const GENERATE_CHECKSUM = "/payment/generate-checksum"//used
export const GET_TRANSACTION = "/payment/get-transaction"
export const CANCEL_ORDER = "order/cancel"
export const UPDATE_ORDER_PAYMENT_TYPE = "order/update-payment-type"
export const GET_TRANSACTION_DETAILS = "order/get-transaction"//used
export const GET_SUMMARY = "/default/summary"//used
export const APPROVED_PRESCRIPTION = "stockist/approved-prescription"
export const GET_DISCOUNT_VALUE = "order/fetch-discount"//used
export const GET_COUPON_VALUE = "/order/fetch-coupon"//used
export const GET_AUDIT_LIST = "/callcenter/fetch-audit-list"//used
export const GET_USERS = "/mr/get-user"
export const GET_ALL_PRODUCTS = "/product/get"
export const GET_PRODUCT_BY_DOCTOR = '/product/get-product-by-doctor'
export const GET_STOCKIST_CODE = "/product/stockist-code"
export const GET_PRODUCT_BY_PLANT_CODE = "/product/get-product-by-plant-code"
export const GET_STATUS_TRACK = "/order/get-status-track"//used
export const ADMIN_INFO = "/admin/info"
export const USER_LIST = "/admin/user-list"
export const SEARCH_BY_DOCTOR = "/admin/search-by-doctor"
export const DOWNLOAD_QR = "/admin/download-qr"
export const GET_ALL_CALL_CENTER = "/admin/get-all-call-center"//used
export const GET_ALL_ORDER_DETAILS = "/admin/get-all-order-count"
export const GET_CUSTOMER_COUNT = "/admin/get-customer-count"//used
export const UPDATE_CALL_CENTER = "/admin/update-call-center"//used
export const CREATE_CALL_CENTER = "/admin/create-call-center"//used
export const REMOVE_CALL_CENTER = "/admin/remove-call-center"//used
export const GET_ALL_STOCKIST = "/admin/get-all-stockist"//used
export const UPDATE_STOCKIST = "/admin/update-stockist"
export const GET_ALL_PRODUCTS_BY_MATERIAL = "/product/get-product-by-material-emrok"
export const UPLOAD_PRODUCT_IMAGE = "/product/change-image"
export const PAYMENT_REFUND = "/payment/get-refund"
export const ORDER_MANAGEMENT_DETAILS = "/order/order-management-list"
//ayan
export const GET_ALL_PRODUCT = "/product/get-all-products"//used
export const ADD_PRODUCT = "/product/add-product"//used
export const GET_DASHBOARD_DETAILS = "/admin/get-dashboard-details"//used
export const GET_GRAPH_DATA = "/admin/get-graph-data"//used
//palash
export const GET_ALL_ADMIN_USERS = "/admin/admin-users"//used
export const UPDATE_ADMIN_USER = "/admin/admin-user/update"//used
export const CREATE_ADMIN_USER = "/admin/admin-user/create"//used
export const ADMIN_USER_UPDATE_STATUS = "/admin/admin-user/update-status"//used
export const GET_ORDER_DETAILS_OUT = "/sap/generate-order-csv";//used
export const CHECK_PINCODE_AVAILABLITY = "/customer/check-pincode-availablity";//used
export const GET_ALL_SCHEME = "/customer/get-all-scheme";//used
export const CHANGE_ORDER_DELIVERY_STATUS = "/order/change-delivery-status";//used
export const GET_COUPON_CODE = "/order/get-coupon-code" //used
export const GET_STATE_CITY = "/customer/get-state-city-by-pincode" //used
export const APPROVED_REJECT_RXN = "/stockist/approved-reject-rxn" //used
export const GET_DOCTOR_PERSONA_LIST = "/admin/doctor-persona/list" //used
export const CREATE_DOCTOR_PERSONA = "/admin/doctor-persona/create" //used
export const REMOVE_DOCTOR_PERSONA = "/admin/doctor-persona/remove" //used
export const UPDATE_DOCTOR_PERSONA = "/admin/doctor-persona/update" //used
export const DOCTOR_PERSONA_DETAIL = "/doctor/details" //used
export const DOCTOR_PERSONA_INFO = "/doctor/info" //used
export const UPLOAD_EXCEL_DATA = "/admin/upload-excel-data" //used
export const SAVE_UPLOAD_EXCEL_FILE = "/admin/save-upload-excel-file" //used
export const UPLOAD_INVOICE = "/stockist/upload-invoice"//used
export const CREATE_STATE_STOCKIST_MAPPING = "/stockist/create-state-stockist-mapping"//used
export const GET_STATE_STOCKIST_MAPPING_LIST = "/stockist/get-state-stockist-mapping"//used
export const REMOVE_STATE_STOCKIST_MAPPING = "/stockist/remove-state-stockist-mapping"//used
export const GET_SCHEME_COUPON = "/product/get-scheme-coupon"//used






