import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "../../../components/Reusable/WithRouter";
import config from "../../../config/emrok.config";
import Common from "../../../hoc/Common.hoc";
import axios from "axios";
import moment from "moment";
import AdminHeader from "../../../components/layout/Admin/AdminHeader";
import AdminSidebar from "../../../components/layout/Admin/AdminSidebar";
import AdminFooter from "../../../components/layout/Admin/AdminFooter";
import "../../../assets/css/admin/style.css";
import "../../../assets/css/admin/responsive.css";
import AdminService from "../../../services/Admin.service";
import CustomDatatable from "../../../components/Datatable/CustomDatatable.component";
import StockistAction from "../../../redux/actions/Stockist.action";
class ProductManagmentDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: [],
      mappingList: []
    }
  }

  static getDerivedStateFromProps(props, state) {
    return {

    }
  }

  componentDidMount() {
    this.getMappingList();
  }
  setMappingData = (data) => {
    let resp = []
    resp = data?.map((item) => ({
      _id: item._id,
      stockist_name: item.user.name || "",
      stockist_code: item.user.stockist_code || "",
      states: item.state_name || "",
      stockist_email: item.user.email || ""
    }))
    return resp
  }

  getMappingList = async () => {
    try {
      this.setState({ mappingList: [] });
      let res = await AdminService.getstateStockistMapping()
      console.log(res, 123333)
      if (res.data.success) {
        console.log(res.data.data, "lasjdhjkash");
        this.setState({ mappingList: res.data.data.mapping });
      }
    } catch (error) {

    }
  }

  addMapping = () => {
    this.props.navigate('/admin/stockist-state-mapping/add');
  }

  handleRemoveMapping = (event) => {
    // alert(event.target.id);
    if (event.target.id != null) {
      this.props.hoc.customAlert('Are you sure you want to remove mapping?', false, "", true, true, async () => {
        let payload = {
          id: event.target.id
        }
        let res = await AdminService.removeStateStockistMapping(payload)
        console.log(res, 123333000)
        if (res.data.success) {
          this.props.hoc.customAlert('Mapping removed successfully', true);
          this.getMappingList();
        }
      }, () => {

      })
    } else {
      this.props.hoc.customAlert('Something went wrong', false)
    }


  }

  render = () => {
    const { mappingList } = this.state;
    let _data = this.setMappingData(mappingList);
    return (
      <>
        <div className="layout-wrapper">
          <AdminHeader />
          <AdminSidebar />
          <div className="main-content">
            <div className="page-content">
              <div className="container-fluid">
                <div className="row g-4">
                  <div className="col-md-5 w-100 d-flex justify-content-between align-items-center">
                    <h5 className="mb-20">Stockist and State Mapping</h5>
                    <button
                      className="btn btn-primary me-4"
                      onClick={this.addMapping}
                    >
                      Add Mapping
                    </button>
                  </div>
                  <div className="row g-4">
                    <div className="table-responsive">
                      {
                        _data && _data.length ? (
                          <CustomDatatable
                            pagination={true}
                            columns={[
                              {
                                name: 'Stockist Name',
                                // selector: row => row.mr_name,

                              },
                              {
                                name: 'Stockist Code',
                                // selector: row => row.mr_name,

                              },
                              {
                                name: 'States',
                                // selector: row => row.mr_name,

                              },
                              {
                                name: 'Action',
                                // selector: row => row.createdAt,

                              }
                            ]}
                            data={this.setMappingData(mappingList)}
                            pageName="stockist_state_mapping"
                            handleRemoveMapping={this.handleRemoveMapping}
                          />)
                          : (
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>Stockist Name</th>
                                  <th>Stockist Code</th>
                                  <th>States</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td colSpan="4" className="text-center">
                                    <strong>No Records Found</strong>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          )
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <AdminFooter />
          </div>
        </div>
      </>
    );
  };
}

const mapDispatchToProps = (dispatch) => {
  return {

  };
};

const mapStateToProps = ({ AdminReducer }) => {

  return {
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Common(ProductManagmentDashboard)));
