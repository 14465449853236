import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from '../../../components/Reusable/WithRouter';
import CustomerHeader from "../../../components/layout/CustomerHeader";
import TrackShipment from '../../../components/Order/TrackShipment';
import ShippingAddress from '../../../components/Order/OrderShippingAddress';
import OrderService from '../../../services/Order.service';
import config from '../../../config/emrok.config';
import CustomerFooter from '../../../components/layout/CustomerFooter';
import Common from '../../../hoc/Common.hoc';
import axios from 'axios';
import OtherService from '../../../services/OtherService';
class OrderInformation extends Component {
    constructor(props) {
        super(props)

        this.state = {
            quantity: "",
            quantity1: 0,
            quantity2: 0,
            bankrefno: "",
            payment_status: "",
            payment_type: ""
        }
    }

    checkQuantity() {
        const { currentOrder } = this.props.OrderReducer;

        let quantity = 0;
        currentOrder.products.forEach((order, i) => {
            if (i === 0) {
                this.setState({ quantity1: order.quantity })
            }
            else {
                this.setState({ quantity2: order.quantity })
            }
            quantity = quantity + order.quantity;
        })
        this.setState({ quantity })
    }
    componentDidMount() {
        this.checkQuantity();
        const currentorder = this.props.OrderReducer.currentOrder;
        console.log(currentorder, "currentorder");
        const order_id = this.props.OrderReducer.currentOrder ? this.props.OrderReducer.currentOrder.order_id : "";

        let payment_status = currentorder ? currentorder.payment_status : ""
        let payment_type = currentorder ? currentorder.payment_type : ""
        this.setState({ payment_type: payment_type, payment_status: payment_status })
        console.log(order_id, "order_id")
        if (order_id != "") {

            OtherService.getTransactionDetails({ order_id: order_id }).then(data => {
                console.log(data.data.success, "alladata")
                if (data.data.success) {

                    // console.log(data.data.data[0].bank_ref_no,"data12345");

                    if (data.data.data.length > 0) {
                        this.setState({ bankrefno: data.data.data[0].bank_ref_no })
                    }


                    // this.props.hoc.customAlertWithClick("Your order is cancelled ", true)
                    // this.props.navigate('/customer/registration');
                }
                else {
                    // alert("Something went wrong.")
                }
            })

            let payload = {

                _id: currentorder ? currentorder._id : null
            };
            OrderService.getOrderById(payload).then(res => {
                console.log(res, "resss");
                if (res && res.data && res.data.success && res.data.data) {
                    console.log(res.data.data.order[0], "123333")
                    let paymenttype = res.data.data.order[0].payment_type;
                    let paymentstatus = res.data.data.order[0].payment_status;
                    this.setState({ payment_type: paymenttype, payment_status: paymentstatus })


                }
            }).catch(err => {
            })

        }
    }

    orderItemListJsx = () => {
        const { currentOrder } = this.props.OrderReducer;
        const { quantity, quantity1, quantity2 } = this.state;
        return (
            <>
                {
                    currentOrder.products.map((product, i) => {
                        return (
                            <>
                                <h6>
                                    <div className="tablts-dtls">
                                        <div>{product.name}</div>
                                        <div>₹{product.price}  {i === 0 ? " x " + quantity1 : " x " + quantity2}</div>
                                    </div>
                                </h6>
                            </>
                        )
                    })
                }

            </>
        )
    }

    orderCancel = () => {
        console.log(this.props.OrderReducer, "this.props.OrderReducer");
        this.props.hoc.customAlert('Are you sure you want to proceed?', true, "", true, true, () => {
            if (this.props.OrderReducer.currentOrder && this.props.OrderReducer.currentOrder._id) {
                let payload = {
                    order_id: this.props.OrderReducer.currentOrder._id,
                    status: "cancelled"
                }
                OrderService.updateOrderStatus(payload).then(data => {
                    if (data.success) {
                        this.props.hoc.customAlertWithClick("Your order is cancelled ", true)
                        // this.props.navigate('/customer/registration');
                    }
                    else {
                        alert("Something went wrong.")
                    }
                })

            }



        }, () => {

        })
    }

    handleDownload = async () => {

        const { currentOrder } = this.props.OrderReducer;

        const downloadUrl = config.serviceUrl + "/images/sap/in/Invoice/" + currentOrder.order_id + ".pdf"; // Replace with the actual URL or path of the file
        try {
            const response = await fetch(downloadUrl);
            if (response.status === 200) {
                window.open(downloadUrl, '_blank');
                console.log("present")
            } else {
                // alert("not present")
                this.props.hoc.customAlert("Invoice not yet generated.", false)
                console.log("not present")
            }
        } catch (error) {
            window.open(downloadUrl, '_blank');
            // Handle network or other errors
            console.log("error");
        }
    };

    handleClickHome = () => {

        this.props.navigate('/orders')
    }

    render = () => {
        const { currentOrder } = this.props.OrderReducer;
        const { quantity, quantity1, quantity2, bankrefno, payment_type, payment_status } = this.state;
        const { customer } = this.props.CustomerReducer;
        console.log("?>>>>>>", customer)
        console.log(currentOrder, 'currentOrder');
        return (
            <>
                <section className="order-infrmtn-pt pt-0 order-information-container">
                    <div className="container">
                        <CustomerHeader />
                        {/* Row end */}
                        <div className="row">
                            <div className="ordr-info-part mt-4">
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <h2>Order Information</h2>
                                        <p>
                                            <span className="ordr-fst-id">Order ID</span>
                                            <span className="ordr-num"> {currentOrder.order_id ? currentOrder.order_id : currentOrder._id}</span>
                                        </p>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className="btn-home flotright">
                                            <a href="javascript:void(0);" onClick={this.handleClickHome}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
                                                Back
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 price-details-pt">
                                <div className="price-details-dtls mt-4">
                                    <h5>Price Details</h5>
                                    <h4>Total Quantity ({quantity})</h4>
                                    {
                                        this.orderItemListJsx()
                                    }
                                    <hr />
                                    <h6>
                                        <div className="tablts-dtls">
                                            <div>Gross Total</div>
                                            <div>₹{currentOrder.totalBeforeDiscount ? (parseFloat(+currentOrder.totalBeforeDiscount)).toFixed(2) : 0}</div>
                                        </div>
                                    </h6>

                                    <h6>
                                        <div className="tablts-dtls">
                                            <div> <div>{currentOrder.hasOwnProperty('titleName') && currentOrder.titleName != "" ? currentOrder.titleName : 'Free Gift'} ({currentOrder.free_pen_quantity ? currentOrder.free_pen_quantity : 0})</div>
                                                <div className='scheme-font-size'>{currentOrder.selectedSchemeProduct != "" ? currentOrder.selectedSchemeProduct : ""} </div></div>
                                            <div>₹{currentOrder.free_pen_price ? currentOrder.free_pen_price : 0}</div>
                                        </div>
                                        <div className="tablts-dtls">
                                            <div><div>{currentOrder.hasOwnProperty('titleName_2') && currentOrder.titleName_2 != "" ? currentOrder.titleName_2 : 'Free Gift'} ({currentOrder.free_pen_quantity_2 ? currentOrder.free_pen_quantity_2 : 0})</div>
                                                <div className='scheme-font-size'>{currentOrder.selectedSchemeProduct_2 != "" ? currentOrder.selectedSchemeProduct_2 : ""} </div></div>
                                            <div>₹{currentOrder.free_pen_price_2 ? currentOrder.free_pen_price_2 : 0}</div>
                                        </div>
                                    </h6>
                                    {/* <h6>
                                        <div className="tablts-dtls">
                                            <div>Free Gift ({currentOrder.free_pen_quantity ? currentOrder.free_pen_quantity : 0})</div>
                                            <div>₹{currentOrder.free_pen_price ? currentOrder.free_pen_price : 0}</div>
                                        </div>
                                    </h6> */}
                                    <h6>
                                        <div className="tablts-dtls">
                                            {/* <div className='scheme-font-size'>{currentOrder.selectedSchemeProduct != "" ? currentOrder.selectedSchemeProduct : ""} </div> */}

                                        </div>
                                    </h6>
                                    <h6>
                                        <div className="tablts-dtls">
                                            <div>Scheme/Coupon Applied ({currentOrder.couponCode + ',' + currentOrder.couponCode_2})</div>
                                            <div>₹{currentOrder.couponDiscount ? (parseFloat(+currentOrder.couponDiscount)).toFixed(2) : 0}</div>
                                        </div>
                                    </h6>

                                    <hr />
                                    <h6>
                                        <div className="tablts-dtls">
                                            <div>Total Amount</div>
                                            {currentOrder.payment_type == "cod" ? <div>₹00</div> : <div>₹{currentOrder.total ? (parseFloat(+currentOrder.total)).toFixed(2) : 0}</div>}
                                        </div>
                                    </h6>
                                    {/* <h6>
                                        <div className="tablts-dtls">
                                            <div>Due Amount</div>
                                            {currentOrder.payment_type == "cod" ? <div>₹{currentOrder.total ? (parseFloat(+currentOrder.total)).toFixed(2) : 0}</div> : <div>₹00</div>}
                                        </div>
                                    </h6> */}
                                    <p className='notetext'>* Price Inclusive of Taxes</p>
                                    <hr />
                                </div>
                                <ShippingAddress customer={customer} currentOrder={currentOrder.payment_type} bankrefno={bankrefno} selectOrder={currentOrder} payment_type={payment_type} payment_status={payment_status} />
                            </div>
                            <TrackShipment orderCancel={this.orderCancel} currentOrder={currentOrder} orderplacedDate={currentOrder ? currentOrder.createdAt : ""} />
                            <div className="dwn-btn-trk mt-4 dwn-btn-trk-wrap">
                                <div className="dwn-align-prft">
                                    {/* <button
                                        type="button"
                                        className="feedbk-wrt"
                                        onClick={() => {
                                            console.log(this.props, 898);
                                            this.props.navigate("/customer/feedback-info")
                                        }}
                                    >
                                        Write Feedback
                                    </button> */}
                                    <a
                                        type="button"
                                        className="feedbk-wrt downloadinvoice"
                                        onClick={this.handleDownload}
                                        // href={`${config.serviceUrl}/images/invoice/${currentOrder.order_id}.pdf`}
                                        // download="download"
                                        target='_blank'
                                    >
                                        Download Invoice
                                    </a>
                                </div>
                                <div>
                                    {/* <a href={config.knowMoreLink} className="knw-mr-track" target="_blank" rel="noopener noreferrer">
                                        Know more
                                    </a> */}
                                </div>
                            </div>
                            <CustomerFooter />
                        </div>
                    </div>
                </section>
            </>
        )

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        // setCustomer: (customer) => dispatch(CustomerAction.setCustomer(customer))
    }
}

const mapStateToProps = (state) => {
    return {
        OrderReducer: state.OrderReducer,
        CustomerReducer: state.CustomerReducer
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Common(OrderInformation)))