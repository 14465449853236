import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from '../../../components/Reusable/WithRouter';
import config from '../../../config/emrok.config';
import Common from '../../../hoc/Common.hoc';
import AdminHeader from "../../../components/layout/Admin/AdminHeader";
import AdminSidebar from "../../../components/layout/Admin/AdminSidebar";
import AdminFooter from "../../../components/layout/Admin/AdminFooter";
import '../../../assets/css/admin/style.css';
import '../../../assets/css/admin/responsive.css';
import AdminService from '../../../services/Admin.service';
import AdminUserAction from '../../../redux/actions/AdminUser.action';
import AdminAction from '../../../redux/actions/Admin.action';
import CountryService from '../../../services/Country.service';
import Dropdown from '../../../components/SearchableDropdown/Dropdown.component';

class AdminUserAdd extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedStockist: "",
      selectedStates: "",
      states: [],
      stockistList: []
    }

  }


  componentDidMount() {
    this.getStockist();
    setTimeout(() => {
      this.populateStates("IN")
    }, 500);
  }
  populateStates = (isoCode = null) => {
    if (isoCode) {
      CountryService.getStates(isoCode).then(data => {
        if (data.success) {
          this.setState({ states: data.states }, async () => {
            let _state = this.state.selectedStates || null
            console.log(_state, this.state.states, "jhgjhgjhg");
            if (_state) {
              let stateObj = null
              for (let i = 0; i < this.state.states.length; i++) {
                if (this.state.states[i].name === _state) {
                  stateObj = this.state.states[i]
                }
              }
              console.log(stateObj, _state, 1234545677);
              console.log("stateObj found", stateObj)

            }
          })
        }
      })
    }
  }
  getStockist = async () => {
    try {
      let res = await AdminService.getallStockist()
      console.log(res, 123333)
      if (res.data.success) {
        console.log(res.data.data, "lasjdhjkash");
        this.setState({ stockistList: res.data.data });
      }
    } catch (error) {

    }
  }
  getStates = () => {

  }

  changeVal = async (event) => {
    let name = event.target.name;
    let value = event.target.value;
    this.setState({ selectedStockist: value });

  }

  setNumber = (event) => {

  }
  next = async () => {
    const { selectedStockist, selectedStates, states, stockistList } = this.state;

    if (selectedStates == "") {
      this.props.hoc.customAlert('Please select a state.', false)
      return false

    } else if (selectedStockist == "") {
      this.props.hoc.customAlert('Please select a stockist.', false)
      return false
    } else {
      let payload = {
        selectedStates,
        selectedStockist
      }
      console.log(payload, "payload123");
      // return
      let res = await AdminService.createstateStockistMapping(payload)
      // console.log(res, 123333)
      if (res.data.success) {
        console.log(res.data.data.data, "lasjdhjkash");
        this.props.hoc.customAlert('State and Stockist mapping Added Successfully', true);
        this.props.navigate('/admin/stockist-state-mapping')
      }

    }

  }
  handleClickHome = () => {
    this.props.navigate('/admin/stockist-state-mapping')
  }
  render = () => {
    const { selectedStockist, selectedStates, states, stockistList } = this.state;
    console.log(stockistList, "stockistList")
    return (
      <>
        <div className="layout-wrapper">
          <AdminHeader />
          <AdminSidebar />
          <div className="main-content">
            <div className="page-content">
              <div className="container-fluid">
                <div className="row g-4">
                  <div className='col-md-6'>
                    <h5 className='mb-20 headingstyle'>Add State Stockist Mapping</h5>
                  </div>

                  <div className='col-md-6 '>
                    <div className="btn-home flotright mx-4" onClick={this.handleClickHome}>
                      <a href="javascript:void(0);" >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
                        Back
                      </a>
                    </div>
                  </div>
                  {/* Row end */}
                  <div className="row g-4">
                    <form className="mt-3 registration-form">
                      <div className="row">
                        <div className="col-md-4 mb-3">
                          <label htmlFor="disabledTextInput" className="form-label lvl-clr-hd">State <span className='requiredFld'> * </span></label>
                          {
                            states.length ?
                              <Dropdown
                                items={states}
                                onSelect={(item) => {
                                  if (item) {
                                    this.setState({ selectedStates: item.name })
                                  }
                                }}
                                value={selectedStates}
                              /> :
                              <Dropdown
                                items={states}
                                value={selectedStates}
                              />
                          }

                        </div>
                        <div className="col-md-4 mb-3">
                          <label htmlFor="stockist" className="form-label lvl-clr-hd">
                            Stockist <span className="requiredFld"> * </span>
                          </label>
                          <select
                            name="stockist"
                            value={selectedStockist}
                            onChange={(event) => this.changeVal(event)}
                            className="form-control name-pt-ft"
                          >
                            <option value="">Select Stockist</option>
                            {stockistList.map((stockist) => (
                              <option key={stockist._id} value={stockist._id}>
                                {stockist.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="end-bck-btn-pt">
                          <div>
                            <button type="button" className="next-btn-crcl" onClick={() => this.next()}>
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>

                  </div>
                </div>
              </div>
            </div>
            <AdminFooter />
          </div>
        </div>

      </>
    )

  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    // dispatching plain actions
    setMappingData: (stateStockistMapping) => dispatch(AdminAction.setMappingData(stateStockistMapping))
  }
}

const mapStateToProps = (state) => {
  return {
    AdminUserReducer: state.AdminUserReducer
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Common(AdminUserAdd)))