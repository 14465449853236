import React, { useEffect, useState, useImperativeHandle } from "react";

import IncrementIcon from "../../assets/Svg/IncrementIcon";
import DecrementIcon from "../../assets/Svg/DecrementIcon";
import config from "../../config/emrok.config";
import DropdownCoupon from "../SearchableDropdown/DropdownCoupon.component";
import Dropdown from "../SearchableDropdown/Dropdown.component";
export default function ProductList({ products = [], productIncrement, productDecrement, paymentOrder, product_quantities, couponListOfAllProduct, schemeListOfAllProduct, schemeCouponList, selectedValue, onValueChange, applyCoupon, couponDiscount, isShowApplyBtn, removeCoupon, selectedValue_2, isShowApplyBtn_2 }, ref) {
    const handleChange = (item) => {
        // const item = event.target.value;
        onValueChange && onValueChange(item); // Invoke the callback to send the value to the parent
    };
    const { serviceUrl } = config;
    console.log(products, "hgghhghghg");
    const renderJsx = (_product_quantities) => {
        // return products.map((product, i) => {
        return (
            <>
                <div className="product-dtls-content-box mt-24">
                    <div className="row">

                        {products.map((product, i) =>

                            <div className="col-md-3 col-xs-4 col-sm-4">
                                <div className="product-dtls-image-content product-dtls-image-content-new" style={{ height: '180px' }}>
                                    <div className="product-dtls-image-box">
                                        <img src={`${serviceUrl}/images/${product.poduct_img}`} alt="Product-1" />
                                    </div>
                                </div>
                                <div className="product-dtls-add-product">
                                    <h4 className="product-dtls-add-product-title">
                                        {/* EMROK O - Tablets 500mg */}
                                        {product.name}
                                    </h4>
                                    {/* <p className="product-desc">
                                        { product.description }
                                    </p> */}
                                    <div className="d-flex align-items-center">
                                        <h4 className="product-value pe-2">
                                            ₹<span className="add-product-value">{product.price}</span>
                                        </h4>
                                        <div className="product-increement-decreement-btn">
                                            <button
                                                type="button"
                                                className="decreement-icon"
                                                onClick={() => {
                                                    console.log("test")
                                                    productDecrement(product._id);
                                                }}
                                            >
                                                <DecrementIcon />
                                            </button>
                                            <span>{_product_quantities[i]?.quantity}</span>
                                            {/* <span>{product.quantity}</span> */}
                                            <button
                                                type="button"
                                                className="increement-icon"
                                                onClick={() => {
                                                    console.log("test>>>>>>")
                                                    productIncrement(product._id);
                                                }}
                                            >
                                                <IncrementIcon />
                                            </button>
                                        </div>
                                    </div>
                                    {product.material == '404706' ? <p style={{ fontSize: 10, paddingTop: 10, color: 'rgb(10, 79, 115)', float: 'left' }}><b>Note:</b>  Minimum Order quantity is 5 units and maximum order quantity is 10 units.</p> : <p style={{ fontSize: 10, paddingTop: 10, color: 'rgb(10, 79, 115)', float: 'left' }}><b>Note:</b>  Minimum Order quantity is 6 units and maximum order quantity is 12 units.</p>}
                                    {/* <div>
                                        <p style={{ fontSize: 12 }}>
                                            <b>Coupon</b>
                                        </p>
                                        {couponListOfAllProduct && couponListOfAllProduct.length > 0 ? (
                                            couponListOfAllProduct.filter((coupon) => coupon.material === product.material).length > 0 ? (
                                                couponListOfAllProduct
                                                    .filter((coupon) => coupon.material === product.material) // Filter coupons by product.material
                                                    .map((coupon, i) => (
                                                        <p style={{ fontSize: 10 }} key={i}>
                                                            {coupon.coupon_id}
                                                        </p>
                                                    ))
                                            ) : (
                                                <p style={{ fontSize: 10 }}>No coupons available</p>
                                            )
                                        ) : (
                                            <p style={{ fontSize: 10 }}>No coupons available</p>
                                        )}
                                    </div>
                                    <div>
                                        <p style={{ fontSize: 12 }}>
                                            <b>Scheme</b>
                                        </p>
                                        {schemeListOfAllProduct && schemeListOfAllProduct.length > 0 ? (
                                            schemeListOfAllProduct.filter((scheme) => scheme.material === product.material).length > 0 ? (
                                                schemeListOfAllProduct
                                                    .filter((scheme) => scheme.material === product.material) // Filter schemes by product.material
                                                    .map((scheme, i) => (
                                                        <p style={{ fontSize: 10 }} key={i}>
                                                            {scheme.name}
                                                        </p>
                                                    ))
                                            ) : (
                                                <p style={{ fontSize: 10 }}>No schemes available</p>
                                            )
                                        ) : (
                                            <p style={{ fontSize: 10 }}>No schemes available</p>
                                        )}
                                    </div> */}
                                    <div>

                                        {product.material === '404706' ? (
                                            <div className="mt-5">
                                                <p style={{ fontSize: 12 }}>
                                                    <b>Select Scheme</b>
                                                </p>
                                                <DropdownCoupon
                                                    items={schemeCouponList.filter(coupon => coupon.material === "404706")}
                                                    onSelect={item => {
                                                        if (item) {
                                                            handleChange(item);
                                                        }
                                                    }}
                                                    value={typeof selectedValue === 'string' ? selectedValue : selectedValue?.name || ''}
                                                    classvalue={isShowApplyBtn ? '' : 'disabledropdown'}
                                                />
                                                <div className="col-2 text-end cstm-col-responsive mt-2">
                                                    {isShowApplyBtn ? <button type="button" class="btn btn-outline-primary btn-sm rounded-3 coupon-btn-apply" onClick={() => {
                                                        applyCoupon(product.material);
                                                    }}>
                                                        Apply
                                                    </button> : <button type="button" class="btn btn-outline-primary btn-sm rounded-3 coupon-btn-remove" onClick={() => {
                                                        removeCoupon(product.material);
                                                    }}>
                                                        Remove
                                                    </button>}
                                                </div>
                                            </div>

                                        ) : <p></p>
                                        }
                                        {product.material === '403820' ? (

                                            <div className="mt-5">
                                                <p style={{ fontSize: 12 }}>
                                                    <b>Select Scheme</b>
                                                </p>
                                                <DropdownCoupon
                                                    items={schemeCouponList.filter(coupon => coupon.material === "403820")}
                                                    onSelect={item => {
                                                        if (item) {
                                                            handleChange(item);
                                                        }
                                                    }}
                                                    value={typeof selectedValue_2 === 'string' ? selectedValue_2 : selectedValue_2?.name || ''}
                                                    classvalue={isShowApplyBtn_2 ? '' : 'disabledropdown'}
                                                />
                                                <div className="col-2 text-end cstm-col-responsive mt-2">
                                                    {isShowApplyBtn_2 ? <button type="button" class="btn btn-outline-primary btn-sm rounded-3 coupon-btn-apply" onClick={() => {
                                                        applyCoupon(product.material);
                                                    }}>
                                                        Apply
                                                    </button> : <button type="button" class="btn btn-outline-primary btn-sm rounded-3 coupon-btn-remove" onClick={() => {
                                                        removeCoupon(product.material);
                                                    }}>
                                                        Remove
                                                    </button>}
                                                </div>
                                            </div>
                                        ) : <p></p>
                                        }
                                    </div>

                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </>
        )
        // })
    }
    console.log(product_quantities, "kjsdkjksaj");
    return (
        <>
            <div className="title-wrap">
                {/* <h6 className="sm-title text-uppercase mb-15">Medicine</h6>
                <h1 className="lg-title text-capitalize mb-20">EMROK</h1> */}
                <h5 className="md-title mt-5 productdetails">Product Details</h5>
            </div>
            {/* Title end */}
            {renderJsx(product_quantities)}
            {/* Product details content box end*/}
            {/* <div className="product-notes">
                 <p className="notes-1">Partial payment Min 20% (₹{paymentOrder.partialMinPayment ? parseFloat(+paymentOrder.partialMinPayment).toFixed(2) : 0})</p>
                 <p className="notes-2">Payment Delivery 80% (₹{paymentOrder.deliveryPayment ? parseFloat(+paymentOrder.deliveryPayment).toFixed(2) : 0})</p>
                 <p className="notes-3">Complete Payment 100% (₹{paymentOrder.totalPayment ? parseFloat(+paymentOrder.totalPayment).toFixed(2) : 0})</p>
            </div> */}
            <div className="know-more-text">
                {/* <a
                    href={config.knowMoreLink}
                    className="kow-more-link text-underline"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Know more
                </a> */}
            </div>

        </>
    )
}