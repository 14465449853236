import React, { Component } from 'react';
import Select from 'react-select';

export default class DropdownCoupon extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedOption: null,
      items: [],
      onSelect: undefined,
      onInputChange: undefined,
      isDisabled: false,
      classvalue: "",
      isMultiSelect: false,
    }
    console.log(this.props, 123)
  }
  static getDerivedStateFromProps(props, state) {
    console.log(props, state, 22);
    const getItem = (val) => {
      if (props.items && props.items.length) {
        let item = props.items.find(i => i.name === val)
        return item ? item : null
      }
    }
    return {
      items: props.items || [],
      onSelect: props.onSelect || undefined,
      onInputChange: props.onInputChange || undefined,
      selectedOption: props.value ? getItem(props.value) : null,
      // selectedOption: props.value ? props.value : null,
      isDisabled: props.isDisabled || false,
      classvalue: props.classvalue || "",
      isMultiSelect: props.isMultiSelect || false,

    }
  }

  handleChange = selectedOption => {
    this.state.onSelect && this.state.onSelect(selectedOption)
  }

  handleInputChange = (inputValue) => {
    // if(inputValue.length<=7){
    this.state.onInputChange && this.state.onInputChange(inputValue)
    // }

  };

  render = () => {
    const { selectedOption, items, isDisabled, classvalue, isMultiSelect } = this.state;
    console.log(this.state, items, 56565656);
    return (
      <div className='coupon-drop'>
        <Select
          value={selectedOption}
          onChange={this.handleChange}
          // onInputChange={this.handleInputChange}
          options={items}
          isSearchable
          // placeholder="Select an option..."
          // isDisabled={isDisabled}

          blurInputOnInputChange={false}
          // styles={{

          // }}
          className={classvalue}
        />
      </div>
    );
  }
}
