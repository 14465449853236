import React from "react";
import LogoutIcon from "../../../assets/Svg/LogoutICon";
import CommonAction from "../../../redux/actions/Common.action";
import MrAction from "../../../redux/actions/Mr.action";
import StockistAction from "../../../redux/actions/Stockist.action";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import OrderAction from "../../../redux/actions/Order.action";
import CustomerAction from "../../../redux/actions/Customer.action";
import FeedbackAction from "../../../redux/actions/Feedback.action";
export default function StockistHeader() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const logout = () => {

        dispatch(StockistAction.logout());
        dispatch(CommonAction.logout());
        dispatch(OrderAction.clearState());
        dispatch(CustomerAction.clearState());
        dispatch(FeedbackAction.clearState());

        // const id = customer && customer.prescribedDoctor && customer.prescribedDoctor._id;
        const url = "/user";
        navigate(url)
    }
    return (
        <>
            <div className="row">
                <div className="w-100 logo-top-box mb-0">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="logo-wrap">
                            <a href="javascript:void(0);"><img src={require("../../../assets/images/dgf-logo.png")} alt="logo" /></a>
                        </div>
                        <div className="d-flex">
                            <a href="/assets/user_manual/Diabetes Golden Forum - User Manual - Stockists.pdf" title="User Manual" target="_blank" style={{ marginRight: "10px", marginTop: "5px" }}><img src="/assets/icons/user-guide.png" style={{ width: "30px" }} /></a>
                            <button type="submit" className="btn-logout" onClick={() => { logout() }}>
                                <LogoutIcon />
                                Logout
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}