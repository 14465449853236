import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from '../../../components/Reusable/WithRouter';
import CustomerHeader from "../../../components/layout/CustomerHeader";
import CallCenterHeader from "../../../components/layout/CallCenter/CallCenterHeader";
import TrackShipmentUser from '../../../components/Order/TrackShipmentUser';
import ShippingAddress from '../../../components/Order/OrderShippingAddress';
import OrderService from '../../../services/Order.service';
import config from '../../../config/emrok.config';
import CustomerFooter from '../../../components/layout/CustomerFooter';
import Common from '../../../hoc/Common.hoc';
import axios from 'axios';
import OtherService from '../../../services/OtherService';
import moment from 'moment';
class TrackInfoOrder extends Component {
    constructor(props) {
        super(props)

        this.state = {
            quantity: "",
            quantity1: 0,
            quantity2: 0,
            selectOrder: [],
            bankrefno: "",
            rtochangeDate: "",
            refundchangeDate: "",

        }

    }

    checkQuantity() {
        const selectOrder = this.props.OrderReducer.selectedOrders[0];
        console.log(selectOrder, "seelct order");
        let quantity = 0;
        selectOrder.products.forEach((order, i) => {
            if (i === 0) {
                this.setState({ quantity1: order.quantity })
            }
            else {
                this.setState({ quantity2: order.quantity })
            }
            quantity = quantity + order.quantity;
        })
        let fre_pen_quanity = selectOrder.free_pen_quantity ? selectOrder.free_pen_quantity : 0;
        quantity = quantity + fre_pen_quanity;
        this.setState({ quantity })
    }
    componentDidMount() {
        // alert("in did mount");
        const allOrders = this.props.OrderReducer.selectedOrders[0];
        console.log(allOrders, "allOrders123")
        this.setState({ selectOrder: allOrders });
        console.log(allOrders, "selectedOrders");
        this.checkQuantity();

        const order_id = allOrders ? allOrders.order_id : "";
        console.log(order_id, "order_id")
        if (order_id != "") {

            OtherService.getTransactionDetails({ order_id: order_id }).then(data => {
                console.log(data, "alladata")
                if (data.data.success) {

                    // console.log(data.data.data[0].bank_ref_no,"data12345");


                    if (data.data.data.length > 0) {
                        console.log(data.data.data[0], "alladata123")
                        this.setState({ bankrefno: data.data.data[0].bank_ref_no })
                    }

                    // this.props.hoc.customAlertWithClick("Your order is cancelled ", true)
                    // this.props.navigate('/customer/registration');
                }
                else {
                    // alert("Something went wrong.")
                }
            })

            OtherService.getStatusTrack({ order_id: order_id }).then(response => {

                if (response.data.success) {
                    console.log(response, "statustrack")

                    const changeDate = response.data.data[0].change_date;
                    if (changeDate != "" && response.data.data[0].status == "RTO") {
                        this.setState({ rtochangeDate: changeDate })
                    }
                    if (changeDate != "" && response.data.data[0].status == "Refund Completed") {
                        this.setState({ refundchangeDate: changeDate })
                    }
                    // console.log(changeDate,"changeDate")
                }
            })
        }
    }

    goBackPrevious = () => {
        const usertype = this.props.CommonReducer.userType;

        if (usertype == "callcenter") {
            this.props.navigate('/callcenter/list');
        }
        if (usertype == "mr") {
            this.props.navigate('/mr/list');
        }
        if (usertype == "bu") {
            this.props.navigate('/bu/list');
        }
        if (usertype == "nsm") {
            this.props.navigate('/nsm/list');
        }
        if (usertype == "zm") {
            this.props.navigate('/zm/list');
        }
        if (usertype == "rm") {
            this.props.navigate('/rm/list');
        }
        if (usertype == "doctor-persona") {
            this.props.navigate('/doctor-persona/dashboard');
        }
        if (usertype == "manufactur") {
            this.props.navigate('/manufactur/list');
        }
        if (usertype == "stockist") {
            this.props.navigate('/stockist/list');
        }
    };


    orderItemListJsx = () => {
        const selectOrder = this.props.OrderReducer.selectedOrders[0];
        console.log(selectOrder, "selectOrder123");

        const { quantity, quantity1, quantity2 } = this.state;
        return (
            <>
                {
                    selectOrder.products.map((product, i) => {
                        return (
                            <>
                                <h6>
                                    <div className="tablts-dtls">
                                        <div>{product.name}</div>
                                        <div>₹{product.price}  {i === 0 ? " x " + quantity1 : " x " + quantity2}</div>
                                    </div>
                                </h6>
                            </>
                        )
                    })
                }

            </>
        )
    }

    orderCancel = () => {
        console.log(this.props.OrderReducer, "this.props.OrderReducer");
        if (this.props.OrderReducer.selectedOrders[0] && this.props.OrderReducer.selectedOrders[0]._id) {
            let payload = {
                order_id: this.props.OrderReducer.selectedOrders[0]._id,
                status: "cancelled"
            }
            OrderService.updateOrderStatus(payload).then(data => {
                if (data.success) {
                    this.props.hoc.customAlertWithClick("Your order is cancelled ", true)
                    // this.props.navigate('/customer/registration');
                }
                else {
                    alert("Something went wrong.")
                }
            })

        }
    }

    handleDownload = () => {
        const selectOrder = this.props.OrderReducer.selectedOrders[0];
        const downloadUrl = config.serviceUrl + "/images/sap/in/Invoice/" + selectOrder.order_id + ".pdf"; // Replace with the actual URL or path of the file
        window.open(downloadUrl, '_blank');
    };

    render = () => {
        const selectOrder = this.props.OrderReducer.selectedOrders[0];

        console.log(selectOrder, "selectOrderselectOrder")

        const { quantity, quantity1, quantity2, bankrefno, rtochangeDate, refundchangeDate } = this.state;
        const customer = this.props.OrderReducer.selectedOrders[0].customer;
        console.log("?>>>>>>", customer)
        const usertype = this.props.CommonReducer.userType;
        return (
            <>
                <section className="order-infrmtn-pt pt-0 order-information-container">
                    <div className="container">
                        <CallCenterHeader />
                        {/* Row end */}
                        <div className="row">
                            <div className="ordr-info-part mt-4">
                                <div className='row mt-20'>
                                    <div className='col-md-6'>
                                        <h2>Order Information</h2>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className="btn-home flotright">
                                            <a href="javascript:void(0);" onClick={this.goBackPrevious}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
                                                Back
                                            </a>
                                        </div>
                                    </div>

                                </div>
                                <p>
                                    <span className="ordr-fst-id">Order ID</span>
                                    <span className="ordr-num"> {selectOrder.order_id ? selectOrder.order_id : selectOrder._id}</span>
                                </p>
                            </div>
                            <div className="col-md-9 price-details-pt">
                                <div className="price-details-dtls mt-4">
                                    <h5>Price Details</h5>
                                    <h4>Total Quantity ({quantity})</h4>
                                    {
                                        this.orderItemListJsx()
                                    }
                                    <hr />
                                    <h6>
                                        <div className="tablts-dtls">
                                            <div>Gross Total</div>
                                            <div>₹{selectOrder.totalBeforeDiscount ? (parseFloat(+selectOrder.totalBeforeDiscount)).toFixed(2) : 0}</div>
                                        </div>
                                    </h6>
                                    <h6>
                                        <div className="tablts-dtls">
                                            <div> <div>{selectOrder.hasOwnProperty('titleName') && selectOrder.titleName != "" ? selectOrder.titleName : 'Free Gift'} ({selectOrder.free_pen_quantity ? selectOrder.free_pen_quantity : 0})</div>
                                                <div className='scheme-font-size'>{selectOrder.selectedSchemeProduct != "" ? selectOrder.selectedSchemeProduct : ""} </div></div>
                                            <div>₹{selectOrder.free_pen_price ? selectOrder.free_pen_price : 0}</div>
                                        </div>
                                        <div className="tablts-dtls">
                                            <div><div>{selectOrder.hasOwnProperty('titleName_2') && selectOrder.titleName_2 != "" ? selectOrder.titleName_2 : 'Free Gift'} ({selectOrder.free_pen_quantity_2 ? selectOrder.free_pen_quantity_2 : 0})</div>
                                                <div className='scheme-font-size'>{selectOrder.selectedSchemeProduct_2 != "" ? selectOrder.selectedSchemeProduct_2 : ""} </div></div>
                                            <div>₹{selectOrder.free_pen_price_2 ? selectOrder.free_pen_price_2 : 0}</div>
                                        </div>
                                    </h6>
                                    <h6>
                                        <div className="tablts-dtls">
                                            {/* <div className='scheme-font-size'>{selectOrder.selectedSchemeProduct != "" ? selectOrder.selectedSchemeProduct : ""} </div> */}

                                        </div>
                                    </h6>
                                    {/* <h6>
                                        <div className="tablts-dtls">
                                            <div>Free Gift ({selectOrder.free_pen_quantity ? selectOrder.free_pen_quantity : 0})</div>
                                            <div>₹{selectOrder.free_pen_price ? selectOrder.free_pen_price : 0}</div>
                                        </div>
                                    </h6> */}
                                    {/* <h6>
                                        <div className="tablts-dtls">
                                            <div>Discount({selectOrder.discountApplied}%)</div>
                                            <div>₹{selectOrder.discountAmount ? (parseFloat(+selectOrder.discountAmount)).toFixed(2) : 0}</div>
                                        </div>
                                    </h6> */}
                                    <h6>
                                        <div className="tablts-dtls">
                                            {/* <div>Scheme/Coupon Applied ({selectOrder.couponCode})</div> */}
                                            <div>Scheme/Coupon Applied ({selectOrder.couponCode + ',' + selectOrder.couponCode_2})</div>
                                            <div>₹{selectOrder.couponDiscount ? (parseFloat(+selectOrder.couponDiscount)).toFixed(2) : 0}</div>
                                        </div>
                                    </h6>
                                    <hr />
                                    <h6>
                                        <div className="tablts-dtls">
                                            <div>Total Amount</div>
                                            {selectOrder.payment_type == "cod" ? <div>₹00</div> : <div>₹{selectOrder.total ? (parseFloat(+selectOrder.total)).toFixed(2) : 0}</div>}
                                        </div>
                                    </h6>
                                    {/* <h6>
                                        <div className="tablts-dtls">
                                            <div>Due Amount</div>
                                            {selectOrder.payment_type == "cod" ? <div>₹{selectOrder.total ? (parseFloat(+selectOrder.total)).toFixed(2) : 0}</div> : <div>₹00</div>}
                                        </div>
                                    </h6> */}
                                    <p className='notetext'>* Price Inclusive of Taxes</p>
                                    <hr />
                                </div>
                                <ShippingAddress customer={customer} usertype={usertype} currentOrder={selectOrder.payment_type} bankrefno={bankrefno} selectOrder={selectOrder} />
                            </div>
                            <TrackShipmentUser orderCancel={this.orderCancel} currentOrder={selectOrder} orderplacedDate={selectOrder ? selectOrder.createdAt : ""} orderPickUpdate={selectOrder.awbtrack ? selectOrder.awbtrack.ShipmentPickupDate : ""} awbno={selectOrder.awbtrack ? selectOrder.awbtrack.AWBNo : ""} orderConfirmdDate={selectOrder.confirm_date ? selectOrder.confirm_date : ""} rtochangeDate={rtochangeDate} refundchangeDate={refundchangeDate} />
                            <div className="dwn-btn-trk mt-4 dwn-btn-trk-wrap">
                                <div className="dwn-align-prft">
                                </div>
                                <div>
                                    {/* <a href={config.knowMoreLink} className="knw-mr-track" target="_blank" rel="noopener noreferrer">
                                        Know more
                                    </a> */}
                                </div>
                            </div>
                            <CustomerFooter />
                        </div>
                    </div>
                </section>
            </>
        )

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        // setCustomer: (customer) => dispatch(CustomerAction.setCustomer(customer))
    }
}

const mapStateToProps = (state) => {
    return {
        OrderReducer: state.OrderReducer,
        CustomerReducer: state.CustomerReducer,
        CommonReducer: state.CommonReducer,
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Common(TrackInfoOrder)))