import {
    SET_ADMIN,
    CLEAR_STATE,
    SET_ACTIVE_MENU,
    STORE_SEARCH_VALUE,
    SET_DASHBOARD_DETAILS,
    SET_GRAPH_DATA,
    SET_ACTIVE_DASHBOARD_CLICK,
    SET_DOCTOR_PERSONA_DETAILS,
    SET_STATE_STOCKIST_MAPPING,
    STORE_SEARCH_FILTER_DATA
} from '../types/Admin.type'

const initState = {
    admin: {},
    token: null,
    activeItem: 'dashboard',
    storeSearchData: {
        callcenterdetails: null,
        admin: null,
        storeSearchData: null,
        customerid: "",
        scallcenterid: "",
        callcenterList: [],
        callcenterinfo: "",
        callcenterdetailsList: [],
        countries: [],
        states: [],
        towns: [],
        showTowns: false,
        selectedState: "",
        selectedTown: "",
        selectedDoctor: "",
        fromdate: "",
        todate: "",
        currentDate: "",
        doctorList: [],
        isSearch: false,
        isShowCancelModal: false,
        cancelOrderId: null,
        cancelReason: "",
        mobileno: "",
        summaryCols: ["Total no. of Registration", "Total no. of Order", "Total order Fulfilled", "Payment Process", "Order Pending", "Order Delivered"],
        summaryDetails: null,
        setorderStatus: "",
        exportData: null,
        fileName: "Callcenter_Data",
        graphData: null,
        graphOptions: null,
        payment_status_filter: "",
        delivery_status_filter: ""
    },
    adminDashboardDetails: {},
    activeDashboardClick: "",
    doctorPersonaDetails: {},
    stateStockistMapping: [],
    storeSearchFilterData: {
        payment_status_filter: "",
        delivery_status_filter: ""
    }
}

export default (state = initState, action) => {
    const { type, payload } = action
    console.log(action, 565656);
    switch (type) {
        case SET_ADMIN:
            return Object.assign({}, state, { admin: { ...payload } })
        case SET_ACTIVE_MENU:
            return Object.assign({}, state, { activeItem: payload })
        case SET_ACTIVE_DASHBOARD_CLICK:
            return Object.assign({}, state, { activeDashboardClick: payload })
        case STORE_SEARCH_VALUE:
            return Object.assign({}, state, { storeSearchData: payload })
        case SET_DASHBOARD_DETAILS:
            return Object.assign({}, state, { adminDashboardDetails: { ...payload } })
        case SET_DOCTOR_PERSONA_DETAILS:
            return Object.assign({}, state, { doctorPersonaDetails: { ...payload } })
        case SET_GRAPH_DATA:
            return Object.assign({}, state, { graphData: payload.graphData, graphOptions: payload.graphOptions })
        case SET_STATE_STOCKIST_MAPPING:
            return Object.assign({}, state, { stateStockistMapping: payload })
        case STORE_SEARCH_FILTER_DATA:
            return Object.assign({}, state, { storeSearchFilterData: payload })
        case CLEAR_STATE:
            return initState;
        default:
            return state
    }
}